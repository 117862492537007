/* You can add global styles to this file, and also import other style files */
@import "../node_modules/ng-zorro-antd/ng-zorro-antd.min.css";
@import "../node_modules/ng-zorro-antd/style/index.min.css";
@import "../node_modules/ng-zorro-antd/button/style/index.min.css";

.px-0{
  padding-right:0!important;
  padding-left:0!important;
}
.text-italic{
  font-style: italic;
}
.text-grey {
  color: #8f9ba7;
}
body {
  margin: 0 !important;
  font-family: "Montserrat";
}
.d-block{
  display: block!important
}
.font-normal {
  font-weight: normal !important;
}
.bg-grey {
  background-color: #fafafa;
}
.on-hover .text-hover:hover {
  text-decoration: underline;
}
.cursor-pointer {
  cursor: pointer;
}
.p-15{
  padding: 15px;
}
.p-0 {
  padding: 0px;
}
.pt-0 {
  padding-top: 0px;
}
.pr-0 {
  padding-right: 0px !important;
}
.pr-15 {
  padding-right: 15px;
}
.pl-0 {
  padding-left: 0px !important;
}
.pl-10 {
  padding-left: 10px !important;
}
.pl-15 {
  padding-left: 15px;
}
.pb-0 {
  padding-bottom: 0px !important;
}
.mt-0 {
  margin-top: 0px !important;
}
.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-45 {
  margin-top: 45px;
}
.mr-0{
  margin-right:0!important
}
.ml-10 {
  margin-left: 10px;
}
.ml-5 {
  margin-left: 5px;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}
.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right;
}
.flex-center {
  display: flex;
  align-items: center;
}
.flex-end {
  display: flex;
  align-items: end;
}
.justify-content-between{
  justify-content: space-between;
}
.flex-column-start {
  display: flex;
  flex-direction: column;
  align-items: start;
}
.date-range {
  margin-bottom: 10px;
}
.table {
  margin-top: 20px;
}
.flex-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.font-weight-bold {
  font-weight: bold;
}
.font-weight-bolder {
  font-weight: bolder;
}
.text-capitalize{
  text-transform: capitalize;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-black {
  color: #1b1b1b;
}
.text-success-darken {
  color: #51a351 !important;
}
.text-info-darken {
  color: #0699ab !important;
}
.text-info-default {
  color: #07b2c7 !important;
}
.text-warning-darken {
  color: #f97900 !important;
}
.text-warning-light-2 {
  color: #ffb46c !important;
}
.text-secondary-transparent {
  color: #d3e0e8 !important;
}
.text-primary-light {
  color: #ea5455 !important;
}
.text-primary-light-2 {
  color: #e88787 !important;
}
.text-secondary-darken {
  color: #6c757d !important;
}
.text-yellow-darken {
  color: #ffd543 !important;
}
.text-secondary-light {
  color: #909ca7 !important;
}
.text-secondary-light-2 {
  color: #a8b5c1 !important;
}
.text-warning {
  color: #ff9f43;
}
.text-info {
  color: #00cfe8;
}
.text-yellow-darken {
  color: #ffd543;
}
.text-success {
  color: #28c76f !important;
}
.text-danger {
  color: #eb445a !important;
}

.custom-tooltip {
  width: 250px;
}

.custom-tooltip .header {
  padding: 10px;
  background: #ebebeb;
  font-weight: 600;
  color: #1b1b1b !important;
}

.custom-tooltip .item {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.color-series {
  display: inline-block;
  width: 13px;
  height: 13px;
  border-radius: 3px;
  margin-right: 10px;
}

.text-percentage {
  font-size: 18px;
  font-weight: 500;
}

.border-top {
  border-top: 1px solid #ebebeb;
}

.border-bottom {
  border-bottom: 1px solid #ebebeb;
}

.ft-11 {
  font-size: 11px;
}

.ft-12 {
  font-size: 12px;
}

.ft-13 {
  font-size: 13px;
}

.ft-11 {
  font-size: 11px;
}
.ft-20 {
  font-size: 20px;
}
